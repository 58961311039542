import {useHistory, useLocation} from 'react-router';
import React, {useState, useEffect, useCallback} from 'react';
//icons
import BusinessIcon from '@mui/icons-material/Business';
import SuitcaseIcon from '@mui/icons-material/BusinessCenter';
// components
import {
  StaticBanner,
  StatsWithIcon,
  LayoutContainer,
  BackgroundPaper,
} from 'mui-core';
import JobLayout from './Layout';
import HonestJobLayout from './HonestJobLayout';
import FilterForm from './components/FilterForm';
import {Stack, Box, Typography} from '@mui/material';
import RelatedSearch from './components/RelatedSearch';
// utils
import {useForm} from 'react-hook-form';
import {jobsBanner} from 'assets/images';
import jobsStaticData from 'data/jobs.json';
import {useTheme} from '@mui/material/styles';
import {useInstance, useOpportunitiesData} from 'core/hooks';

const {
  bannerData: {
    bannerDesc,
    bannerTitle,
    jobsAvailLabel,
    jobsAvailCount,
    companyHiringLabel,
    companyHiringCount,
  },
  defaultLocation,
} = jobsStaticData || {};
let showWelcomeMsg = false;

const defaultParams = {
  page: 1,
  page_size: 16,
  sort: 'date_desc',
};

const Jobs = ({
  company = '',
  tabPage = false,
  search_query = '',
  showSearch = true,
  title = 'Job Finder',
  disableBGcolor = false,
  subTitle = 'Search for the perfect job and take the next step in your career journey.',
  relatedSearch = [],
}) => {
  const form = useForm();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const {reset, handleSubmit} = form;
  const [source, setSource] = useState('');
  const [filters, setFilters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const [initialFilters, setInitialFilters] = useState(filters);
  const {
    jobsData,
    clearJobs,
    fetchAllJobs,
    fetchHonestJobs,
  } = useOpportunitiesData();
  const {data: {data: jobsList = []} = {}, request: requestJobsList} =
    jobsData || {};
  const {
    data: {
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = super_type_name
    ? `${city ? city + ', ' : ''}${state_code}`
    : defaultLocation;
  const isCriminalJustice = super_type_name === 'Criminal Justice Organization';

  const apiCall = (Obj, filters = null) => {
    if (filters['job_source'] === 'all') {
      delete filters['job_source'];
    }
    if (filters['job_source'] === 'all' && isCriminalJustice) {
      filters['job_source'] = 'cos';
    }
    fetchAllJobs(Obj, filters);
  };

  const honestJobApiCall = (Obj, filters = null) => {
    if (filters['job_source'] === 'all') {
      delete filters['job_source'];
    }
    fetchHonestJobs(Obj, filters);
  };

  const refetchData = (Obj, newFilters, loadMore) => {
    setReqParams(Obj);
    let updatedFilters = {...filters, ...newFilters};
    setFilters(updatedFilters);
    if (
      updatedFilters?.job_source === 'honest' ||
      updatedFilters?.job_source === 'cos'
    ) {
      // Do nothing
    } else {
      updatedFilters['radius'] = '';
    }
    if (
      updatedFilters?.no_of_days === 0 ||
      updatedFilters?.no_of_days === null
    ) {
      delete updatedFilters['no_of_days'];
    }

    updatedFilters?.job_source === 'honest'
      ? honestJobApiCall(Obj, updatedFilters)
      : apiCall(Obj, updatedFilters, loadMore);
  };

  const onPageChange = page => {
    window.scrollTo({top: 660, behavior: 'smooth'});
    const Obj = Object.assign({}, reqParams, {
      page,
    });
    refetchData(Obj, filters, true);
  };

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
    };
    const search = new URLSearchParams(location.search);
    const job_source = decodeURIComponent(search.get('source') || '');
    if (search_query) {
      updatedFilters['title'] = search_query;
    }
    if (company) {
      updatedFilters['company_name'] = company;
    }
    if (job_source) {
      updatedFilters['job_source'] = job_source;
      setSource(job_source);
    }
    if (isCriminalJustice) {
      if (!job_source) {
        updatedFilters['job_source'] = 'cos';
        setSource('cos');
      }
    }
    if (relatedSearch?.length > 0) {
      updatedFilters['title'] = relatedSearch[0];
    }
    setInitialFilters(updatedFilters);
    // temporary fix for user experience on prod for global instance,
    // later remove the condition & always call the refetchData func
    if (
      company ||
      search_query ||
      super_type_name ||
      relatedSearch?.length > 0
    ) {
      refetchData(reqParams, updatedFilters);
    } else {
      showWelcomeMsg = true;
      setReqParams(reqParams);
      setFilters(updatedFilters);
    }
    return () => {
      clearJobs();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSourceChange = e => {
    const {value = ''} = e?.target;
    onResetClick(false, value, false);
    setFilters({...filters, job_source: value});
  };

  const onFinish = useCallback(
    (values, source) => {
      showWelcomeMsg = false;
      let filterApply = false;
      let newFilters = {...filters, ...values};
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            const intVal = parseInt(values[key]);
            values[key] = intVal;
            newFilters[key] = intVal;
            if (values[key] === 0) {
              values[key] = null;
              newFilters[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            sort: 'date_desc',
            page: 1,
          });

          if (source === 'local') {
            values['radius'] = '';
          }
          refetchData(Obj, newFilters);
          history.replace({
            search: '',
          });
          onDrawerClose();
        }
      }
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFilterReset = (resetObj, closeDrawer = true, callApi = true) => {
    closeDrawer && onDrawerClose();
    // temporary fix for user experience on prod for global instance,
    // later remove the condition & always call line of refetchData
    if (
      company ||
      search_query ||
      super_type_name ||
      relatedSearch?.length > 0
    ) {
      callApi && refetchData(defaultParams, resetObj);
    } else {
      clearJobs();
      showWelcomeMsg = true;
      setReqParams(defaultParams);
      setFilters({...filters, resetObj});
    }
  };

  const onSelectCareerName = name => {
    refetchData(reqParams, {...filters, title: name});
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const onResetClick = (closeDrawer = true, source = '', callApi = true) => {
    const resetObj = {
      title: '',
      company: '',
      work_arr: '',
      radius: '20',
      job_type: '',
      no_of_days: 0,
      job_source: source,
      location_type: '',
      location: initialFilters?.location || '',
    };
    setSource(source);
    reset(resetObj);
    onFilterReset(resetObj, closeDrawer, callApi);
  };

  const dataSet = {
    data: jobsList,
    request: requestJobsList,
  };

  return (
    <LayoutContainer
      title={title}
      dataSet={dataSet}
      subTitle={subTitle}
      typeOfUi='fullPage'
      nameOfComponent='jobs'
      showResultCount={false}
      showSearch={showSearch}
      disableBGcolor={disableBGcolor}
      hideEmpty={showWelcomeMsg && !company && !search_query}
      banner={
        !tabPage ? (
          <Box mb={4}>
            <StaticBanner
              url={jobsBanner}
              desc={bannerDesc}
              title={bannerTitle}>
              <Stack direction='row' gap='15px'>
                <StatsWithIcon
                  data={[
                    {
                      title: jobsAvailLabel,
                      count: jobsAvailCount,
                      icon: <SuitcaseIcon style={{color: 'white'}} />,
                    },
                    {
                      title: companyHiringLabel,
                      count: companyHiringCount,
                      icon: <BusinessIcon style={{color: 'white'}} />,
                    },
                  ]}
                />
              </Stack>
            </StaticBanner>
          </Box>
        ) : null
      }
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: 'title',
        onSearch: value => onFinish(value),
        searchPlaceholder: 'Search by job title or other keyword',
      }}
      filterForm={
        <FilterForm
          form={form}
          filters={filters}
          hideCompanyFilter={company}
          onResetClick={onResetClick}
          isDrawerOpen={isDrawerOpen}
          onDrawerClose={onDrawerClose}
          onSourceChange={onSourceChange}
          source={filters['job_source'] || source}
          handleSubmit={handleSubmit(value => onFinish(value, source))}
        />
      }>
      {Array.isArray(relatedSearch) && relatedSearch.length > 0 && (
        <RelatedSearch
          relatedSearch={relatedSearch}
          onClick={onSelectCareerName}
        />
      )}

      {showWelcomeMsg && !company && !search_query ? (
        <Box pb={3}>
          <BackgroundPaper
            mt={2}
            py={10}
            display='flex'
            borderRadius={4}
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            background={{
              dark: theme.palette.darkGray.main,
              light: theme.palette.purplebg4perc.main,
            }}>
            <Typography mb={2} variant='h3' color='text.primary'>
              Welcome to Job Finder
            </Typography>
            <Typography
              width='40%'
              lineHeight={2}
              variant='bodyXL'
              textAlign='center'
              color='text.primary'>
              Start by typing a job title or keyword to search thousands of jobs
              and internships across the United States. Use filters to narrow
              down your search and find the right fit for you.
            </Typography>
          </BackgroundPaper>
        </Box>
      ) : (source === 'honest' &&
          jobsList &&
          jobsList[0]?.job_source === 'honest') ||
        (jobsList && jobsList[0]?.job_source === 'honest') ? (
        <HonestJobLayout
          reqParams={reqParams}
          showSearch={showSearch}
          onPageChange={onPageChange}
        />
      ) : (
        <JobLayout
          reqParams={reqParams}
          showSearch={showSearch}
          onPageChange={onPageChange}
        />
      )}
    </LayoutContainer>
  );
};

export default Jobs;
