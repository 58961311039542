import {useEffect} from 'react';
import {
  Box,
  Grid,
  Radio,
  Stack,
  Divider,
  RadioGroup,
  Typography,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';
import {LocationSearch} from 'mui-core';
import {createField} from 'mui-core/Form';
import jobsStaticData from 'data/jobs.json';

const {
  filterForm: {
    labels,
    jobTypes,
    placeholders,
    datePostedOptions,
    workArrangementOptions,
  },
} = jobsStaticData || {};

const LocalJobsFilterForm = ({form, filters, nearbyMSA, hideCompanyFilter}) => {
  const {setValue, getValues} = form;

  useEffect(() => {
    if (filters?.location) {
      setValue('location', filters.location);
    }
  }, [filters, setValue]);

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const CompanyField = createField('company', {
    form,
    label: labels.company,
    defaultValue: getValues('company') || '',
  })(
    <OutlinedInput label={labels.company} placeholder={placeholders.company} />,
  );

  return (
    <Stack spacing={3}>
      {createField('location', {
        form,
        defaultValue: getValues('location') || '',
      })(
        <LocationSearch
          calledFromForm
          LocationOptions={nearbyMSA}
          onInputChange={onInputChange}
          onLocationChange={handleLocationChange}
          filters={{location: getValues('location')}}
        />,
      )}
      {!hideCompanyFilter && CompanyField}
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.datePosted}
        </Typography>
        <Grid container>
          {createField('no_of_days', {
            form,
            defaultValue: getValues('no_of_days') || 0,
          })(
            <RadioGroup row>
              {datePostedOptions.map((item, index) => (
                // <Grid item xs={6} md={6} key={index}>
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
                // </Grid>
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.jobType}
        </Typography>
        <Grid container>
          {createField('job_type', {
            form,
            defaultValue: getValues('job_type') || '',
          })(
            <RadioGroup row>
              {jobTypes.map((item, index) => (
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Typography mb={1} variant='h5' color='text.primary'>
          {labels.workArrangement}
        </Typography>
        <Grid container>
          {createField('location_type', {
            form,
            defaultValue: getValues('location_type') || '',
          })(
            <RadioGroup row>
              {workArrangementOptions.map((item, index) => (
                <FormControlLabel
                  key={index}
                  sx={{width: 'calc(50% - 5px)'}}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>,
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default LocalJobsFilterForm;
