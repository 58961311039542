import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch, connect} from 'react-redux';
import Careers from './components/Careers';
import {queryStringParse} from 'core/utils';
import Overview from './components/Overview';
import {Box, Container} from '@mui/material';
import {alpha, useTheme} from '@mui/material/styles';
import Requirements from './components/Requirements';
import {programDetailsData} from 'data/programs.json';
import {useHistory, useParams} from 'react-router-dom';
import {API} from 'config';
import {
  TabPage,
  EmptyBox,
  ErrorBoundary,
  AppBreadcrumb,
  ProgramBanner,
  BackgroundPaper,
  JobOpportunities,
  RequestErrorLoader,
  LayoutContainer,
} from 'mui-core';
import {
  isPathLoading,
  getPathDetails,
  fetchPathDetails,
  clearPathDetails,
  getStudentOnboard,
  fetchStudentOnboard,
  getActivePath,
  onboardTypeSelector,
  fetchOnboardTypes,
  fetchActivePaths,
  deleteActivePath,
  savedPathSelector,
  savePath,
  fetchSavedPaths,
  removeSavedPath,
  clearStudentOnboard,
  clearOnboardTypes,
  getOccupations,
  fetchAllOccupations,
  clearAllOccupations,
} from 'redux/modules/pathways';
import {
  clearComparePrograms,
  fetchComparePrograms,
} from 'redux/modules/programs';
import {
  getSubscribedOccupation,
  fetchSubscribedOccupation,
  resetSubscribedOccupation,
} from 'redux/modules/occupation';
import {openLoginScreen} from 'redux/modules/auth';
import {useAuth} from 'core/hooks';
import ProgramModals from './components/Modals';
import {Services} from 'core/Services';
import {useSnackbar} from 'notistack';
import {licenseTypeKeys} from 'config';
import OnBoardSteps from './components/OnBoardSteps';
import {NewJobs} from 'app/modules';
import {Apprenticeships} from 'app/modules/opportunities';

const services = new Services();
const tabKeys = [
  'overview',
  'requirements',
  'careers',
  'jobs',
  'apprenticeships',
  // 'opportunities',
];
let tempOnBoardTypes = {};
let tempToken = '';
let initDataLoading = false;

const ProgramDetails = ({
  studentOnboard,
  onboardTypes,
  fetchStudentOnboard,
  clearComparePrograms,
  fetchComparePrograms,
  openLoginScreen,
  fetchOnboardTypes,
  deleteActivePath,
  savedPath,
  savePath,
  fetchSavedPaths,
  removeSavedPath,
  clearStudentOnboard,
  clearOnboardTypes,
  clearPathDetails,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const {
    location: {search},
  } = history;

  // redux
  const [token, user] = useAuth();
  const dispatch = useDispatch();
  const occupations = useSelector(getOccupations);
  const programData = useSelector(getPathDetails);
  const isProgramLoading = useSelector(isPathLoading);
  const {request: subscribedOccupationReq} = useSelector(
    getSubscribedOccupation,
  );

  //router
  const params = queryStringParse(search);
  const {programName, programId, view = ''} = useParams();

  // states
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [programActionData, setProgramActionData] = useState({
    type: '',
    data: null,
  });

  // others
  const {tabsTitles, overviewData} = programDetailsData;
  const {request: studentOnboardRequest} = studentOnboard || {};
  const isCriminalJustice =
    programData?.institute_details?.super_type_name ===
    licenseTypeKeys?.criminalJustice;

  const clearLogoutData = () => {
    clearPathDetails();
    setProgramActionData({type: ''});
    clearOnboardTypes();
    clearStudentOnboard();
  }; // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //clear
    return () => {
      clearLogoutData();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    token && !subscribedOccupationReq && dispatch(fetchSubscribedOccupation());
    return () => {
      dispatch(resetSubscribedOccupation());
    };
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (programData?.program_details?.program_uuid && !occupations?.request) {
      if (occupations && !occupations.allOccupations) {
        dispatch(fetchAllOccupations());
      }
    }
    return () => {
      dispatch(clearAllOccupations());
    };
  }, [programData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    clearPathDetails();
    programId && dispatch(fetchPathDetails('', '', '', programId));
  }, [programId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentStudentOnBoardData = studentOnboardD => {
    const {uuid} = programData || {};
    const {data} = studentOnboardD || studentOnboard || {};
    if (data && uuid) {
      if (Array.isArray(data)) {
        const d = data.filter(i => i.path_uuid === uuid);
        return d[0] ? d[0] : null;
      } else {
        return null;
      }
    }
    return null;
  };

  const openPathWayAction = useCallback((addToProfile, active) => {
    let enrollment_URL = '';
    const onboardData =
      onboardTypes && onboardTypes.data
        ? onboardTypes
        : {data: tempOnBoardTypes};
    const {data: onboardTypesData = []} = onboardData;
    const isPathActive = getCurrentStudentOnBoardData() || active;
    if (isPathActive) {
      if (
        isPathActive?.completed_stage === 'onboard' ||
        isPathActive?.completed_stage === 'complete'
      ) {
        const enrollment_url = isPathActive?.enrollment_url;
        setProgramActionData({
          type: 'remove',
          data: enrollment_url ? {enrollment_url} : null,
        });
      }
      // isPathActive?.enrollment_url &&
      //   setEnrollmentURL(isPathActive?.enrollment_url);
      // isPathActive?.completed_stage === 'onboard' ||
      // isPathActive?.completed_stage === 'complete' ||
      // isPathActive?.enrollment_url
      //   ? setEnrollModalVisible(true)
      //   : handleExistStudentOnboard(studentOnboard.data);
      return;
    }
    if (addToProfile) {
      setTimeout(() => {
        const EnrollmentType = onboardTypesData.filter(onBoard_Type => {
          if (onBoard_Type?.enrollment_url) {
            enrollment_URL = onBoard_Type?.enrollment_url;
            return onBoard_Type;
          }
        });
        if (enrollment_URL) {
          onSelectOnboardType(EnrollmentType[0], true);
        } else {
          const ob_types = onboardTypesData.filter(item =>
            item.enrollment_url ? false : true,
          );
          onSelectOnboardType(ob_types[0], true);
        }
      }, 300);
      return;
    }
    // const {data = []} = onboardTypes;
    onboardTypesData &&
      Array.isArray(onboardTypesData) &&
      onboardTypesData.forEach(onBoard_Type => {
        if (onBoard_Type?.enrollment_url) {
          enrollment_URL = onBoard_Type.enrollment_url;
        }
      });
    if (enrollment_URL) {
      setProgramActionData({type: 'url', data: enrollment_URL});
    } else {
      setProgramActionData({type: 'onboard', data: null});
    }
  });

  const onStartPathwayClick = useCallback(
    (_, addToProfile) => {
      // disableed below if condition to work for multiple active program
      // if (studentOnboard.data) {
      //   handleExistStudentOnboard(studentOnboard.data);
      //   return;
      // }
      if (token) {
        openPathWayAction(addToProfile);
      } else {
        openLoginScreen({
          callback: async data => {
            tempToken = data.access;
            const {institute_details, uuid} = programData || {};
            tempOnBoardTypes = await fetchOnboardTypes(institute_details);
            const studentOnboardData = await fetchStudentOnboard(programData);
            let isPathActive = null;
            if (studentOnboardData && uuid) {
              if (Array.isArray(studentOnboardData)) {
                const data = studentOnboardData.filter(
                  i => i.path_uuid === uuid,
                );
                isPathActive = data[0] ? data[0] : null;
              } else {
                isPathActive = null;
              }
            }
            openPathWayAction(addToProfile, isPathActive);
          },
        });
      }
    },
    [
      fetchOnboardTypes,
      fetchStudentOnboard,
      openLoginScreen,
      openPathWayAction,
      programData,
      token,
    ],
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const initializeDataForLoginUser = async () => {
    // this function calls the  aps'is that are required only if user logged in (onboard, plan, workforce)
    if (initDataLoading) return;
    initDataLoading = true;
    const {institute_details} = programData || {};
    // (!skills?.data || !skills.data?.length) &&
    //   !skills?.request &&
    //   fetchSkills();
    // (!awardTypes?.data || !awardTypes.data?.length) &&
    //   !awardTypes?.request &&
    //   fetchAwardTypes();
    if (savedPath && !savedPath.request) fetchSavedPaths();
    if (!studentOnboardRequest) await fetchStudentOnboard(programData); //pathdata is required since if the current path and active onboard path is same then only getStudentOnboard selector gives the data
    clearOnboardTypes();
    if (onboardTypes && !onboardTypes.request && !onboardTypes.data)
      await fetchOnboardTypes(institute_details);
    initDataLoading = false;
  };

  const isCurrentPathActive = useMemo(
    () => () => {
      const {data} = studentOnboard || {};
      const {uuid} = programData || {};
      if (data && uuid) {
        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].path_uuid === uuid) {
              return true;
            }
          }
        } else {
          if (data.path_uuid === uuid) {
            return true;
          }
        }
      }
      return false;
    },
    [programData, studentOnboard],
  );

  const onEnrollNowClick = (e, close) => {
    const enrollmentURL = programActionData.data;
    setProgramActionData({type: ''});
    if (close) {
      return;
    }
    const {data = []} = onboardTypes;
    const EnrollmentType = data.filter(onBoard_Type => {
      if (onBoard_Type?.enrollment_url) {
        return onBoard_Type;
      }
    });
    !isCurrentPathActive() && onSelectOnboardType(EnrollmentType[0]);
    window.open(enrollmentURL, '_blank');
  };

  const saveOnBoardStep = (completed_stage, uuid) => {
    const {data: studentOnboardData} = studentOnboard || {};
    const {student_onboard_uuid} = studentOnboardData || {};
    // const completed = getCompletedStepIndex();
    // if (step <= completed) return;
    const body = {
      completed_stage,
    };
    return services
      .createUpdateRecord(
        token,
        `${API.gps.student_onboard}/${student_onboard_uuid || uuid}`,
        body,
        'PATCH',
      )
      .then(res => {
        fetchStudentOnboard(programData);
        // if (res && res.completed_stage === 'career')
        // setFinishPathwayModal(true);
      });
  };

  const onSelectOnboardType = (onboardType, addToProfile) => {
    console.log(onboardType, addToProfile);
    setProgramActionData({type: ''});
    const {institute_details: {institution_id} = {}} = programData || {};
    const completed_steps = [];
    const completed_tasks = [];
    let completed_stage = onboardType?.enrollment_url
      ? 'onboard'
      : 'explore_paths';
    if (addToProfile) {
      completed_stage = 'onboard';
      onboardType?.steps.map(step => {
        completed_steps.push(step.step_uuid);
        step.tasks.map(task => {
          completed_tasks.push(task.uuid);
        });
      });
    }
    const onboardData = {
      path_uuid: programData?.uuid,
      onboarding_type:
        onboardType?.enrollment_url || isCriminalJustice
          ? null
          : onboardType?.OnboardingType?.uuid,
      completed_step_uuid: completed_steps,
      completed_task_uuid: completed_tasks,
      completed_stage,
      institute_uuid: institution_id,
    };
    if (onboardType?.enrollment_url) {
      onboardData['enrollment_url'] = onboardType?.enrollment_url;
    }
    isCriminalJustice &&
      programData?.program_url_link &&
      (onboardData['enrollment_url'] = programData?.program_url_link);
    // setOnboardType(false);
    // setLoading(true);
    services
      .createUpdateRecord(
        token || tempToken,
        API.gps.student_onboard,
        onboardData,
        'POST',
      )
      .then(res => {
        if (!res.enrollment_url) {
          !addToProfile && setProgramActionData({type: 'onboard-steps'});
          // !addToProfile &&
          //   saveOnBoardStep('explore_paths', res.student_onboard_uuid);
        }
        if (res && addToProfile) {
          enqueueSnackbar('Program successfully added to your dashboard', {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
        // setLoading(false);
        fetchStudentOnboard(programData);
      })
      .catch(err => {
        // setLoading(false);
        enqueueSnackbar('Error in starting the pathway', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      });
  };

  const handleSavePath = async (savedPathData, openRemoveModal) => {
    const {uuid, institute_details: {institution_id} = {}} = programData || {};
    const {data} = savedPathData || savedPath || {};
    const exists =
      data && Array.isArray(data) && data.filter(i => i.path_uuid === uuid);
    if (exists && exists.length > 0) {
      if (openRemoveModal) {
        setProgramActionData({type: 'remove_save'});
      } else {
        enqueueSnackbar('Already Saved', {
          variant: 'warning',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }

      return;
    }
    if (uuid) {
      await savePath(uuid, institution_id, res => {
        if (res && res.save_path_uuid) {
          enqueueSnackbar('Program successfully saved to your dashboard', {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
      });
      fetchSavedPaths();
    }
  };
  const onSavePathClick = () => {
    if (token) {
      handleSavePath(null, true);
    } else {
      openLoginScreen({
        callback: async () => {
          const {data} = savePath || {};
          if (!data)
            fetchSavedPaths(data => {
              handleSavePath({data});
            });
          else handleSavePath();
        },
      });
    }
  };

  const removeSavedProgram = async () => {
    const {uuid} = programData || {};
    setProgramActionData({type: ''});
    if (uuid) {
      await removeSavedPath(uuid);
      enqueueSnackbar('Saved program removed from your dashboard', {
        variant: 'success',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
      fetchSavedPaths();
    }
  };

  const onAddToProfile = () => {
    onStartPathwayClick('', true);
  };
  const {related_careers: programRelatedCareers = []} = programData || {};
  let relatedCareersList = [];
  let programSearchKey = programName;
  if (programRelatedCareers?.length > 0) {
    relatedCareersList = programRelatedCareers;
    programSearchKey = relatedCareersList[0];
  } else if (occupations?.allOccupations?.occupation?.length > 0) {
    relatedCareersList = occupations?.allOccupations?.occupation?.map(
      occ => occ.name,
    );
    programSearchKey = relatedCareersList[0];
  }

  const tabs = useMemo(() => {
    let availableTabs = [
      {
        key: tabKeys[0],
        title: tabsTitles[0],
        children: (
          <Container maxWidth={'xl'}>
            <Box py={2}>
              <Overview
                savedPath={savedPath}
                programData={programData}
                overviewData={overviewData}
                onAddToProfile={onAddToProfile}
                studentOnboard={studentOnboard}
                onSavePathClick={onSavePathClick}
                onStartPathwayClick={onStartPathwayClick}
                isCurrentPathActive={isCurrentPathActive()}
              />
            </Box>
          </Container>
        ),
      },
      {
        key: tabKeys[1],
        title: tabsTitles[1],
        children: (
          <Container maxWidth={'xl'}>
            <Box py={2}>
              <Requirements programData={programData} />
            </Box>
          </Container>
        ),
      },
      // commenting as part of prod hot fix
      // {
      //   key: tabKeys[2],
      //   title: tabsTitles[2],
      //   children: (
      //     <Box py={2}>
      //       <Careers />
      //     </Box>
      //   ),
      // },
      {
        key: tabKeys[3],
        title: tabsTitles[3],
        children: (
          <Box pt={6}>
            {view === 'jobs' && (
              <NewJobs
                tabPage={true}
                disableBGcolor
                subTitle={`Discover jobs tailored to the specific skills you’ve developed through this program.`}
                // company={bpData?.name}
                // fixedScrollHeight={630}
                title='Jobs Aligned with This Program'
                relatedSearch={relatedCareersList}
                programTitle={decodeURIComponent(programSearchKey)}
              />
            )}
          </Box>
        ),
      },
      {
        key: tabKeys[4],
        title: tabsTitles[4],
        children: (
          <Box pt={6}>
            {view === 'apprenticeships' && (
              <Apprenticeships
                tabPage={true}
                disableBGcolor
                subTitle={`Connect your learning with real-world experience through apprenticeships designed to complement your program.`}
                // company={bpData?.name}
                // fixedScrollHeight={630}
                title='Apprenticeships Aligned with This Program'
                // relatedSearch={relatedCareersList}
                programTitle={decodeURIComponent(programSearchKey)}
              />
            )}
          </Box>
        ),
      },
      // {
      //   key: tabKeys[5],
      //   title: tabsTitles[5],
      //   children: (
      //     <Box pt={2}>
      //       <JobOpportunities
      //         // fixedScrollHeight={630}
      //         title='Jobs & Opportunities'
      //         relatedSearch={relatedCareersList}
      //         programTitle={decodeURIComponent(programSearchKey)}
      //       />
      //     </Box>
      //   ),
      // },
    ];
    if (
      !programData?.program_map
      // || (programData?.terms && !programData.terms?.length))
    ) {
      delete availableTabs[1];
    }
    return availableTabs.filter(ele => ele != null);
  }, [
    savedPath,
    tabsTitles,
    programName,
    programData,
    overviewData,
    onAddToProfile,
    studentOnboard,
    onSavePathClick,
    programSearchKey,
    relatedCareersList,
    isCurrentPathActive,
    onStartPathwayClick,
  ]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onTabChange = index => {
    setActiveTab(index);
    history.push(
      `/program-details/${programName}/${programId}/${tabs[index].key}`,
    );
  };

  const initOnBoardStep = studentOnboard => {
    const currentStudentOnboard = getCurrentStudentOnBoardData(studentOnboard);
    if (
      currentStudentOnboard &&
      currentStudentOnboard.completed_stage === 'explore_paths'
    )
      setProgramActionData({type: 'onboard-steps'});
  };

  const onRemoveActiveProgram = async (hideMsg, stayScreen) => {
    setLoader(true);
    const currentStudentOnboard = getCurrentStudentOnBoardData();
    const onBoard_uuid = currentStudentOnboard.student_onboard_uuid;
    if (!stayScreen) setProgramActionData({type: ''});
    if (onBoard_uuid) {
      await deleteActivePath(onBoard_uuid, err => {
        if (err) {
          enqueueSnackbar(err, {
            variant: 'success',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        } else {
          if (!hideMsg)
            enqueueSnackbar('Program removed successfully', {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
        }
      });
      clearStudentOnboard();
      fetchStudentOnboard(programData);
    }
    setLoader(false);
  };

  const onGoBackOnBoard = async () => {
    await onRemoveActiveProgram(true, true);
    const {data = []} = onboardTypes;
    let enrollment_URL;
    data &&
      Array.isArray(data) &&
      data.forEach(onBoard_Type => {
        if (onBoard_Type?.enrollment_url) {
          enrollment_URL = onBoard_Type.enrollment_url;
        }
      });
    if (enrollment_URL) {
      setProgramActionData({type: 'url', data: enrollment_URL});
    } else {
      setProgramActionData({type: 'onboard', data: null});
    }
  };

  const onOnboardCancel = async () => {
    await onRemoveActiveProgram(true, true);
    setProgramActionData({type: ''});
  };

  const openLogin = () => {
    openLoginScreen({});
  };

  useEffect(() => {
    let activeIdx = 0;
    if (view) {
      tabs.map((tab, idx) => {
        if (tab.key === view) {
          activeIdx = idx;
        }
      });
      if (!tabKeys.includes(view)) {
        history.push(
          `/program-details/${programName}/${programId}/${tabKeys[activeIdx]}`,
        );
      }
    }
    setActiveTab(activeIdx);
  }, [view, tabs]);

  useEffect(() => {
    // initializeDataForLoginUser();
    if (programData) {
      if (token) {
        initializeDataForLoginUser();
      } else {
        // clearSavedPaths();
      }
      // programData?.uuid && fetchComparePrograms(programData?.uuid);
    }
    if (!token) {
      setProgramActionData({type: ''});
    }
  }, [programData, token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    programData?.uuid &&
      dispatch(fetchComparePrograms(decodeURIComponent(programData?.uuid)));
    return () => dispatch(clearComparePrograms());
  }, [programData?.uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (programActionData.type !== 'onboard-steps' && !studentOnboard.request)
      initOnBoardStep(studentOnboard);
  }, [studentOnboard]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BackgroundPaper
      background={theme.palette.purplebg4perc.main}
      id='pg-details'>
      {/* <Container maxWidth={'xl'}> */}
      <RequestErrorLoader
        fullScreen
        hideBackground
        overrideNoDataContainer={
          <EmptyBox emptyText='Program details not found' />
        }
        body={{
          request:
            isProgramLoading ||
            loader ||
            studentOnboardRequest ||
            onboardTypes.request,
          data: programData,
        }}
      />
      <Container maxWidth={'xl'}>
        <AppBreadcrumb
          dataList={[
            {
              name: 'Programs',
              path: '/programs',
            },
            {
              name: decodeURIComponent(programName ? programName : ''),
            },
          ]}
        />
      </Container>
      <ErrorBoundary nameOfComponent='program-details' typeOfUi='subPage'>
        {programActionData.type === 'onboard-steps' ? (
          <Container maxWidth={'xl'}>
            <OnBoardSteps
              studentOnboard={getCurrentStudentOnBoardData()}
              onboardTypes={onboardTypes}
              onCancelOnboard={onOnboardCancel}
              programData={programData}
              fetchStudentOnboard={fetchStudentOnboard}
              onGoBackOnBoard={onGoBackOnBoard}
            />
          </Container>
        ) : (
          <LayoutContainer
            disableBGcolor
            dataSet={{data: [{}]}}
            showSearch={false}
            showDivider={false}
            enableFullWidth
            showFilterBtn={false}
            showResultCount={false}
            banner={
              <Container pt={3} maxWidth={'xl'}>
                <ProgramBanner
                  programData={programData}
                  token={token}
                  openLogin={openLogin}
                />
              </Container>
            }>
            {programData?.program_details?.program_uuid ? (
              <Box sx={{mt: 4}}>
                <TabPage
                  list={tabs}
                  active={activeTab}
                  onChange={(e, index) => onTabChange(index)}
                />
              </Box>
            ) : (
              <Container maxWidth={'xl'}>
                <Box sx={{mt: -5}}>
                  <Overview
                    savedPath={savedPath}
                    programData={programData}
                    overviewData={overviewData}
                    onAddToProfile={onAddToProfile}
                    studentOnboard={studentOnboard}
                    onSavePathClick={onSavePathClick}
                    onStartPathwayClick={onStartPathwayClick}
                    isCurrentPathActive={isCurrentPathActive()}
                  />
                </Box>
              </Container>
            )}
          </LayoutContainer>
        )}
      </ErrorBoundary>
      {/* </Container> */}
      <ProgramModals
        enrollURLData={{
          visible: programActionData.type === 'url',
          onOk: onEnrollNowClick,
          onCancel: e => onEnrollNowClick(e, true),
        }}
        removeProgramData={{
          visible: programActionData.type === 'remove',
          onOk: onRemoveActiveProgram,
          data: programActionData?.data,
          onCancel: () => {
            setProgramActionData({type: ''});
          },
        }}
        removeSaved={{
          visible: programActionData.type === 'remove_save',
          onOk: removeSavedProgram,
          onCancel: () => {
            setProgramActionData({type: ''});
          },
        }}
        onBoardData={{
          onboardTypes,
          onOk: onSelectOnboardType,
          onCancel: () => {
            setProgramActionData({type: ''});
          },
          visible: programActionData.type === 'onboard',
        }}
      />
    </BackgroundPaper>
  );
};

const mapStateToProps = state => ({
  studentOnboard: getStudentOnboard(state),
  onboardTypes: onboardTypeSelector(state),
  activePaths: getActivePath(state),
  savedPath: savedPathSelector(state),
});

export default connect(mapStateToProps, {
  fetchComparePrograms,
  clearComparePrograms,
  fetchStudentOnboard,
  fetchOnboardTypes,
  fetchActivePaths,
  openLoginScreen,
  deleteActivePath,
  savePath,
  fetchSavedPaths,
  removeSavedPath,
  clearStudentOnboard,
  clearOnboardTypes,
  clearPathDetails,
})(ProgramDetails);
